// color
$PRIMARY_COLOR: #FA0A5F;
$SECONDARY_COLOR: #322878;
$PURPLE_COLOR: #231950;
$TEXT_COLOR: #323232;
$TEXT_INACTIVE_COLOR: #C5C5C5;
$PURPLE_BLOOD_COLOR: #E1E6FF;
$BACKGROUND_COLOR: #FAFAFA;
$NAV_BAR_HOVER_COLOR: #FAFAFA;
$NAV_BAR_TEXT_COLOR: #313131;
$WHITE_COLOR: #FFF;
$BLACK_COLOR: #000;
$TITLE_COLOR: #F9F9F9;
$TEXT_TITLE_COLOR: #353535;
$LIGHT_PINK_COLOR: #FFD2CD;
$PINK_COLOR: #FF9BA0;
$PURPLE_BLUE_COLOR: #6478E6;

$DARK_GRAY_COLOR: #A9A9A9;
$DARK_GRAY_COLOR-1: #F7F7F7;
$BORDER_COLOR: #f1f1f1;
$BGCOLOR-ICON: #D1D1D1;
// fonts
$PRIMARY_FONT:'Circular Std', sans-serif;
$SECONDARY_FONT:'Circular Std', serif;

$SCROLLBAR_WIDTH: 10px;
$LIGHT_GRAY_COLOR: #D8D8D8;

// Status Color
$COLOR_STATUS_AVAILABLE: #ff0000;
$COLOR_STATUS_ACCEPTED: #0000ff;
$COLOR_STATUS_REJECTED: #343a40;
$COLOR_STATUS_FULLILLED: #fec00d;
$COLOR_STATUS_SUBMITTED: #008000;
$COLOR_STATUS_WORKING: #FBBCDB;
$COLOR_STATUS_DONE: #ffa500;
$COLOR_STATUS_CANCELLED: #000000;
$COLOR_STATUS_LOSTED: #696969;
$COLOR_STATUS_CUSTOM: #9C69AD;

$breakpoint-tablet: 768px;

$COMMON_BORDER: 1px solid #e0e0e0;
$INACTIVE_LINK_COLOR: #999999;
$WHITE_COLOR: #fff;
$DARK_GRAY_COLOR: #a9a9a9;

$COMMON_TEXT_COLOR: #252525;

$NAVIGATION_COLOR: #999999;
$NAVIGATION_HOVER_COLOR: #fa0a5f;
$COMMON_TEXT_COLOR: #252525;

