.tv-daterangepicker {
  margin-bottom: 12px;
  width: 100%;
  label {
    margin-bottom: 8px;
    line-height: 1;
  }
  .react-datepicker {
    font-family: Circular Std;
    min-width: 320px;
    min-height: 320px;
    width: 100%;
    border: 1px solid #e0e0e0;
    color: #252525;

    &__triangle {
      display: none;
    }
    &-popper[data-placement^="bottom"] {
    }

    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      margin: 16px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 4px;
    }

    &__current-month {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-transform: capitalize;
    }

    &__navigation {
      height: 48px;
      width: 48px;
      top: 16px;
      &:focus {
        outline: none;
      }
      &--next {
        right: 16px;
      }
      &--previous {
        left: 16px;
      }
      &-icon {
        top: 5px;
        &:before {
          border-color: #252525;
          border-width: 2px 2px 0 0;
        }
      }
    }

    &__month-container {
      width: 100%;
    }
    &__month-wrapper {
      display: flex;
      justify-content: space-around;
    }
    &__month {
      width: none;
      &--in-range {
        background-color: #fa0a5e99;
        border: 2px solid #fff;
        border-radius: 8px !important;
        color: #fff !important;
        &:hover {
          background-color: #fa0a5e99 !important;
          color: #fff;
        }
      }
      &--in-selecting-range {
        background-color: #fa0a5f;
        color: #fff !important;
        &:hover {
          background-color: #fa0a5e99;
          color: #fff;
        }
      }
      &--range-start,
      &--range-end {
        background-color: #fa0a5f;
        color: #fff !important;
      }
      &--disabled {
        color: #d6d6d6 !important;
      }
    }
    &__month-text {
      width: 100%;
      height: 40px;
      margin: 2px;
      text-transform: capitalize;
      font-weight: 500;
      font-size: 16px;
      color: #252525;

      line-height: 40px;
      margin: 0;
      border-radius: 4px;
      &--selected,
      &--in-range,
      &--in-selecting-range {
        background-color: #fa0a5f;
        color: #fff !important;
        &:hover {
          background-color: #fa0a5e99;
          color: #fff;
        }
      }

      &--outside-month {
        color: #808080;
      }
      &--keyboard-selected {
        background-color: #fa0a5f;
        color: #fff !important;
        &:hover {
          background-color: #fa0a5e99;
          color: #fff;
        }
      }
      &--disabled {
        color: #d6d6d6;
      }
      &--range-start,
      &--range-end {
        background-color: #fa0a5f;
      }
      &-name {
        display: none;
      }
    }
    &__week {
      & > .react-datepicker__day {
        &--range-start {
          border-top-left-radius: 4px !important;
          border-bottom-left-radius: 4px !important;
        }
        &--range-end {
          border-top-right-radius: 4px !important;
          border-bottom-right-radius: 4px !important;
        }
        &--selected,
        &--in-range,
        &--in-selecting-range {
          border-radius: 0px;
          margin-bottom: 1px;
          &:nth-child(1) {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          &:nth-last-child(1) {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
    &__input-container > input {
      width: 100%;
      text-transform: capitalize;
      padding: 14px 16px;
      height: 48px;
      font-size: 16px;
      line-height: 20px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 4px;
      &:focus {
        outline: none;
      }
    }
  }
}
.react-datepicker {
  &-wrapper {
    width: 100%;
  }
  &__input-container {
    width: 100%;
  }
  &__input-container > input {
    width: 100%;
  }
}
