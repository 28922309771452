.tv-avatar {
  display: inline;
  position: relative;
  cursor: pointer;

  &__img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
}
