@import "../../../../Share/styles/variables.scss";
.container {
  width: calc(100% - 40px);
  min-height: 400px;

  border: $COMMON_BORDER;
  border-radius: 8px;
  display: grid;
  padding: 0px 25px 25px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 50px 1fr;
  grid-template-areas:
    "header header header controllers"
    "content content content controllers"
    "content content content controllers";
}
.header {
  display: grid;
  grid-area: header;
  align-items: center;
  height: 48px;
  padding-top: 15px;
  font-weight: 500;
}

.dropdown {
  width: 100%;
  & > label {
    color: $TITLE_COLOR;
    padding: 0 0 14px 0;
  }
}
.tabContainer {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: inline-flex;
  height: 50px;
  border-radius: 8px;
  align-items: center;
  border: $COMMON_BORDER;
  & button {
    text-align: center;
    width: 100%;
  }
}
.downloadArea {
  width: 100%;
  display: flex;
  justify-content: end;
  & i {
    height: 50px;
    border-radius: 50%;
    position: relative;
    border: 1px solid #e0e0e0;

    width: 50px;
    background-color: #677adf;
    font-size: 20px;
    position: absolute;
    color: #ffff !important;
    cursor: pointer;
    &::before {
      left: 13px;
      top: 12px;
      position: absolute;
      color: #ffff !important;
    }
  }
}

.emptyData {
  grid-area: content;
  display: grid;
  justify-content: center;
  align-items: center;
}

.emptyContainer {
  width: calc(100% - 40px);
  min-height: 400px;

  border: $COMMON_BORDER;
  border-radius: 8px;
  display: grid;
  padding: 0px 25px 25px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 50px 1fr;
  grid-template-areas:
    "header header header "
    "content content content "
    "content content content ";
}
