@import "../../../../Share/styles/variables.scss";

.kpi-widget-basic-card {
  &__container {
    border: $COMMON_BORDER;
    border-radius: 8px;
    min-width: clamp(320px, 22vw, 326px);
    height: 225px;
    flex-direction: column;
    position: relative;
    display: flex;
    flex: 1;
    padding: 20px;
    // & span {
    //   width: min(320px, 22%) !important;
    // }
  }
  &__loader-overlay {
    height: 200px;
    bottom: 15px;
    left: 15px;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 19px);
    background-color: #ffffffc4;
  }
  &__data-overlay {
    height: 120px;
    bottom: 15px;
    left: 15px;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 19px);
    background-color: #fff;
    & > span {
      font-weight: 400;
      font-size: 14px;
      color: #74737b;
    }
  }
  @media only screen and (max-width: 768px) {
    &__container {
      width: calc(100vw - 45px) !important;
      min-width: unset;
    }
  }
}
