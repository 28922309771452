@import "../../../../Share/styles/variables.scss";
.tv-topnavbar {
  &__container {
    padding: 15px 32px 0 24px;
    width: 100%;
    height: 72px;
  }
  &__noti-icon {
    padding: 10px 36px;
  }
  &__flex {
    align-items: center;
  }
}
.tv-avatar-list {
  &__menu {
    background-color: $WHITE_COLOR;
    border: 1px solid #e0e0e0;
    box-shadow: 2px 1px 4px 0 hsla(0, 0%, 62%, 0.522);
    box-sizing: border-box;
    border-radius: 4px;
    position: absolute;
    top: 65px;
    right: -40px;
    min-width: 300px;
    z-index: 100;
    text-align: left;
    height: auto;
    padding-bottom: 16px;

    &-item {
      display: block;
      color: $PRIMARY_COLOR;
      display: block;
      font-weight: 500;
      font-size: 14px;
      padding: 8px 16px 8px 16px;
      &.active {
        background-color: #eeeeee;
      }
      &:hover {
        text-decoration: none;
      }
      &--customer-name {
        padding: 10px;
        border-bottom: 1px solid $BORDER_COLOR;
        margin: 0px 10px 8px 10px;
        color: $COMMON_TEXT_COLOR;
        &.tv-avatar-list__menu-item:hover {
          background-color: $WHITE_COLOR;
        }
      }
    }
  }
}
