@import "../../../../Share/styles/variables.scss";
.kpi-home {
  &__container {
    display: flex;
    padding: 15px 10px;
    width: 100vw;
    padding-bottom: 20px;
  }
  &__side-bar {
    display: flex;
    flex-direction: column;
    width: clamp(320px, 22vw, 400px);
    position: relative;
    &-close {
      display: none;
    }
  }
  &__side-bar_closer {
    position: absolute;
    right: 0;
  }
  &__content-area {
    margin: 0 15px;
    width: 76vw;
    gap: 20px;
    display: flex;
    padding-top: 28px;
  }
  &__date-picker {
    display: flex;
  }
  @media only screen and (max-width: 768px) {
    &__container {
      flex-direction: column !important;
      width: 100vw !important;
    }
    &__side-bar {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      &-close {
        display: none;
      }
    }
    &__content-area {
      margin: 0 5px;
      width: 100%;
      gap: 10px;
      display: flex;
      padding-top: 28px;
    }
    &__date-picker {
      width: calc(100vw - 35px);
    }
  }
}

.tv-report-dropdown {
  width: 100%;

  & span.k-dropdown {
    height: 48px !important;
    border: $COMMON_BORDER;
    border-radius: 8px;
    width: 100%;
  }
  & label {
    font-size: 15px;
    color: $COMMON_TEXT_COLOR;
    padding: 0px 0 10px 0;
    font-weight: 500;
  }
  select {
    font: 400 16px/20px $PRIMARY_FONT !important;
    height: 48px !important;
  }
  & .tv-single-assign-form__control {
    font: 400 16px/20px $PRIMARY_FONT !important;
    height: 48px !important;
    padding: 10px 20px;
    .k-dropdown-wrap {
      border: none;
    }
    .k-input {
      font: 400 15px/20px $PRIMARY_FONT !important;
    }
  }
}

.k-list-container {
  border-radius: 8px !important;
  width: 100px;
  .k-popup {
    border-radius: 8px !important;
  }
}

.tv-report-download__floating {
  position: absolute;
  background-color: #677adf;
  align-items: center;
  display: flex;
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  left: -5px;
  top: -50px;
  outline: 0;
  border: none;
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: #6779df9d;
  }
  & > span {
    font-size: 20px;
  }
}
