@import "../../../../Share/styles/variables.scss";

.kpi-widget-basic-table {
  &__container {
    border: $COMMON_BORDER;
    border-radius: 8px;
    min-width: clamp(320px, 22vw, 326px);
    min-height: 218px;
    flex-direction: column;
    position: relative;
    display: flex;
    padding: 20px;

    // & span {
    //   width: min(320px, 22%) !important;
    // }
  }
  &__header {
    display: flex;
    flex-direction: column;
    & > span {
      margin-bottom: 8px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100px;
    &_header,
    &_row {
      @supports (display: grid) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 3fr;
      }
      display: flex;
      justify-content: space-around;
      width: 100%;

      & > div {
        width: 100%;
        display: flex;
        align-items: center;
      }
      & > div:nth-child(2),
      div:nth-child(3) {
        justify-content: flex-end;
      }
      & > div:nth-child(4) {
        margin-left: 10px;
      }
    }
    &_header {
      font-size: 12px;
      line-height: 15px;
      align-items: center;
      padding-bottom: 12px;
      color: #74737b;
    }
    &_row {
      font-size: 14px;
      line-height: 18px;

      align-items: center;
      padding-bottom: 8px;
      color: #252525;
      & > div {
        padding-left: 10px;
      }
      & > div.progress {
        padding-left: 0;
      }
      .progress-bar {
        border-radius: 4px;
      }
    }
  }
  &__loader-overlay {
    position: absolute;
    bottom: 15px;
    left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 19px);
    background-color: #fff;
    height: calc(100% - 90px);
  }
}
