@import "../../../../Share/styles/variables.scss";

.controllers {
  display: grid;
  grid-area: controllers;
  position: relative;

  padding: 50px 0px 0px 20px;
  gap: 20px;
  grid-template-rows: 1fr 0.5fr 0.5fr 0.5fr;
}
.controllersModified {
  display: grid;
  grid-area: controllers;
  position: relative;
  padding: 50px 0px 0px 20px;
  gap: 20px;
  grid-template-rows: 1fr 0.5fr 0.5fr 0.5fr;
}
.dropdown {
  width: 100%;
  & > label {
    color: $TITLE_COLOR;

    padding: 0 0 14px 0;
  }
}
.tabContainer {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: inline-flex;
  height: 50px;
  border-radius: 8px;
  align-items: center;
  border: $COMMON_BORDER;
  & button {
    text-align: center;
    width: 100%;
  }
}
.downloadArea {
  width: 100%;
  display: flex;
  justify-content: end;
  position: absolute;
  bottom: 45px;
  & i {
    height: 50px;
    border-radius: 50%;
    position: relative;
    border: 1px solid #e0e0e0;

    width: 50px;
    background-color: #677adf;
    font-size: 20px;
    position: absolute;
    color: #ffff !important;
    cursor: pointer;
    &::before {
      left: 13px;
      top: 12px;
      position: absolute;
      color: #ffff !important;
    }
  }
}
.tableTotalContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.tableTotalLabel {
  font-size: 14px;
  margin-bottom: 5px;
}
.tableTotal {
  font-size: 20px;
  font-weight: 500;
}
.reportDescription {
  font-size: 14px;
  font-weight: 300;
}
