@import '../../../../Share/styles/variables.scss';

.tv-navigationlink {
  .nav-item,
  .page {
    margin: 3px 18px;
    padding: 5px;
    color: #74737b;
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    &:hover {
      color: $PRIMARY_COLOR;
      text-decoration: none;
    }
    &--active {
      color: $PRIMARY_COLOR;
    }
  }
}
