

// width
$pannelLeftWidth: 250px;
$pannelRightWidth: 1190px;
$leftWidthPercent: $pannelLeftWidth / ($pannelLeftWidth + $pannelRightWidth) * 100%;
$rightWidthPercent: $pannelRightWidth / ($pannelLeftWidth + $pannelRightWidth) * 100%;
$maxWidthConntainer: $pannelLeftWidth + $pannelRightWidth;

@mixin borderTopOnMobile {
  border-top: 1px dashed black;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  overflow-x: hidden;
}

// border-box
html {
  box-sizing: border-box;
  font-size: 16px;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e5e5e5;
  margin: 1em 0;
  padding: 0;
}

.tv-container {
  margin: 0 auto;
}

.tv {
  &-headline-0 {
    font-family: $PRIMARY_FONT;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: -0.5px;
    line-height: 54px;
  }

  &-headline-1 {
    font-family: $PRIMARY_FONT;
    font-size: 32px;
    font-weight: 300;
    letter-spacing: -0.5px;
    line-height: 40px;
  }

  &-headline-2 {
    font-family: $PRIMARY_FONT;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: 32px;
  }

  &-title-text {
    font-family: $PRIMARY_FONT;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.2px;
    line-height: 28px;
  }

  &-body-text {
    font-family: $SECONDARY_FONT;
    font-size: 16px;
    line-height: 24px;
  }

  &-small-text {
    font-family: $SECONDARY_FONT;
    font-size: 14px;
    line-height: 24px;
  }

  &-display-flex {
    display: flex;
    &-coloumn{
      flex-direction: column;
    }
  }

  &-position-rel{
    position: relative
  }

  &-w-50 {
    width: 50%;
  }

  &-w-100 {
    width: 100%;
  }

  // tv: prefix for transvoice
  &-primary-color {
    color: $PRIMARY_COLOR
  }

  &-secondary-color {
    color: $SECONDARY_COLOR
  }

  &-text-color {
    color: $TEXT_COLOR
  }

  &-text-inactive-color {
    color: $TEXT_INACTIVE_COLOR
  }

  &-space {
    &-40 {
      padding-right: 40px;
    }
  }
}


[role="button"],
[class^="tv-buttons__type"],
[class*=" tv-buttons__type"] {
  cursor: pointer;
  outline: none;
  font-size: 16px;
}

.tv-border-radius-shadow {
  background: $WHITE_COLOR;
  border-radius: 5px;
  box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2);
}
.tv-bottom-line{
  border-top: 1px solid #f1f1f1;
}
.tv-is-clipped {
  overflow: hidden;
}
.tv-is-clipped2 {
  overflow: hidden;
}

.m-auto {
  margin: auto;
}

.w-100 {
  width: 100%;
}

.tv-modal{
  &__wrapper{
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
  }
  &__overlay{
    content: "";
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 12;
  }
  &__holder{
    max-height: calc(100vh - 70px);
    z-index: 20;
    padding: 0 10px;
    animation: zoomIn .5s;
    max-width: calc(100vh - 100px);
    overflow-y: auto;
    position: absolute;
  }
  &__btn-close{
    position: absolute;
    right: 20px;
    top: 10px;
  }
}


@mixin make-row() {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.tv-row {
  @include make-row();
}

.tv-row>[class^=tv-col-] {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}

@mixin make-col($size, $columns: 12) {
  flex: 0 0 percentage($size / $columns);
  max-width: percentage($size / $columns);
}

@for $i from 1 through 12 {
  .tv-col-#{$i} {
    @include make-col($i, 12);
  }
}

.tv-mobile {
  &--show {
    display: none !important;
  }
  @media (max-width: $breakpoint-tablet) {
    &--hide {
      display: none !important;
    }
    &--show {
      display: initial !important;
    }
  }
}

.tv-tab {
  &--show {
    display: none !important;
  }
  @media (max-width: 1024px) {
    &--hide {
      display: none !important;
    }
    &--show {
      display: initial !important;
    }
  }
}
*{
  font-family: "Circular Std";
}