@import "../../../../Share/styles/variables.scss";

.kpi-widget-pie-card {
  &__container {
    border: $COMMON_BORDER;
    border-radius: 8px;
    min-width: 200px;
    min-height: 320px;
    width: auto;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    padding: 20px;
    & > .kpi-heading {
      width: unquote("min(320px, 22vw)") !important;
    }
    &--vertical {
      width: clamp(326px, 23vw, 326px);
      height: auto;
    }
    @media (min-width: 1900px) {
      &--vertical {
        width: auto;
        height: auto;
      }
    }
    @media only screen and (max-width: 768px) {
      width: calc(100vw - 45px);
      &--vertical {
        width: calc(100vw - 45px);
      }
    }
  }
  &__legend-area {
    max-width: 320px;
    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      width: 100% !important;
      & > div {
        display: flex;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-self: baseline;
  }
  &__legends {
    width: 10px;
    height: 10px;
    border-radius: 25px;
  }
  &__loader-overlay {
    position: absolute;
    bottom: 15px;
    left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 19px);
    background-color: #fff;
    height: calc(100% - 100px);
  }
}
